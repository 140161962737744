import React, { useState } from "react"
import {
  useCourseStore,
  getCourseNavigation,
  getCourseProgress,
} from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import TextLink from "../../../../../components/textlink"
import Aufgabe02Notation from "../../../../../content/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/aufgabe-02-notation"

const taskProps = {
  courseId: "hoelderlins-reisen",
  chapterId: "01-friedrich-hoelderlin",
  taskId: "korrigieren",
}

const Page = () => {
  const { setAnswer, getTask } = useCourseStore()
  const [showHint, setShowHint] = useState(false)
  const navigation = getCourseNavigation(taskProps)
  const progress = getCourseProgress(taskProps)
  const task = getTask({ ...taskProps, taskId: "korrigieren-aufgabe-02" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/hoelderlins-reisen/01-friedrich-hoelderlin/korrigieren/aufgabe-02-loesung" />
      }
      progress={progress}
    >
      <Seo title="Hölderlin korrigieren" />
      <Stack>
        <Heading as="h1" level={2}>
          Hölderlin korrigieren
        </Heading>
        <Paragraph>Weiter geht's mit dem zweiten Teil ...</Paragraph>

        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  courseId: "hoelderlins-reisen",
                  chapterId: "01-friedrich-hoelderlin",
                  taskId: "korrigieren-aufgabe-02",
                  answer: notations,
                })
              }}
              solution={task.solution}
              showHint={showHint}
            >
              <Aufgabe02Notation />
            </NotationGroup>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Markiere Hölderlins Rechtschreib-Fehler und korrigiere sie.
                Klicke dazu auf das Wort, das du korrigieren möchtest, und trage
                deine Verbesserung ein.
              </Note>
              {!showHint && (
                <TextLink
                  onClick={() => {
                    setShowHint(true)
                  }}
                >
                  Zeig mir die Fehler an
                </TextLink>
              )}
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
